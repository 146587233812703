<template>
  <div :class="color" v-if="!lodash.isEmpty(time)">
    {{ time }}
  </div>
</template>

<script>
import lodash from "lodash";

export default {
  setup() {
    return {
      lodash: lodash,
    };
  },
  props: {
    expiresAt: {
      type: Date,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    warningTime: {
      type: Number,
      default: 600000,
    },
    warningColor: {
      type: String,
      default: "text-warning",
    },
    dangerTime: {
      type: Number,
      default: 180000,
    },
    dangerColor: {
      type: String,
      default: "text-danger",
    },
  },
  data() {
    return {
      timeHandler: null,
      time: "",
      running: false,
      color: "",
    };
  },
  watch: {
    expiresAt() {
      this.start();
    },
  },
  methods: {
    padDigits(number, digits) {
      return (
        Array(Math.max(digits - String(number).length + 1, 0)).join("0") +
        number
      );
    },

    stop() {
      if (this.timeHandler != null) {
        clearInterval(this.timeHandler);
      }
      this.running = false;
    },

    start() {
      this.stop();

      this.timeHandler = setInterval(() => {
        let diff = this.expiresAt.getTime() - new Date().getTime();

        if (diff <= 0) {
          this.$emit("timeover");
          this.time = this.placeholder ? this.placeholder : "00:00:00";
          this.stop();
          return;
        } else {
          if (this.dangerTime > 0 && diff <= this.dangerTime) {
            this.color = this.dangerColor;
          } else if (this.warningTime > 0 && diff <= this.warningTime) {
            this.color = this.warningColor;
          }
        }

        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        diff -= days * (1000 * 60 * 60 * 24);

        const hours = Math.floor(diff / (1000 * 60 * 60));
        diff -= hours * (1000 * 60 * 60);

        const mins = Math.floor(diff / (1000 * 60));
        diff -= mins * (1000 * 60);

        const seconds = Math.floor(diff / 1000);
        diff -= seconds * 1000;

        if (days > 1) {
          this.time = `${days} ${this.$t("text.days")} + `;
        } else if (days == 1) {
          this.time = `${days} ${this.$t("text.day")} + `;
        } else {
          this.time = "";
        }

        this.time += `${this.padDigits(hours, 2)}:${this.padDigits(
          mins,
          2
        )}:${this.padDigits(seconds, 2)}`;
      }, 100);

      this.running = true;
    },
  },

  mounted() {
    this.start();
  },
};
</script>
