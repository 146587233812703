<script>
import SelectAddress from "@/components/field/select_address";
import SelectCountry from "@/components/field/select_country.vue";
import lodash from "lodash";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentMethod } from "@/service/payment";
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Notification from "@/components/notification.vue";
import TimeCounter from "@/components/time-counter";

export default {
  name: "Checkout",
  setup() {
    return {
      v$: useVuelidate(),
      lodash: lodash,
    };
  },
  data() {
    return {
      method: 6,

      billingAddress: null,
      shippingAddress: null,

      billingAddressContact: null,
      billingAddressPhone: null,
      billingAddressCountry: null,
      billingAddressZipCode: null,
      billingAddressFullAddress: null,

      shippingAddressContact: null,
      shippingAddressPhone: null,
      shippingAddressCountry: null,
      shippingAddressZipCode: null,
      shippingAddressFullAddress: null,

      preview: null,

      codeStripe: null,
      stripeCredential: null,
      stripe: null,

      codeRRC: null,

      metadata: {
        stripe: null,
        rrc: null,
      },

      executing: false,

      pendingPayment: false,
    };
  },
  validations() {
    return {
      billingAddressContact: {
        required,
      },
      billingAddressPhone: {
        required,
      },
      billingAddressCountry: {
        required,
      },
      billingAddressZipCode: {
        required,
      },
      billingAddressFullAddress: {
        required,
      },

      shippingAddressContact: {
        required,
      },
      shippingAddressPhone: {
        required,
      },
      shippingAddressCountry: {
        required,
      },
      shippingAddressZipCode: {
        required,
      },
      shippingAddressFullAddress: {
        required,
      },

      preview: {
        required,
      },
    };
  },
  watch: {
    "$store.getters.vChallengeCart"() {
      this.setShippingCountry();
    },
    method() {
      this.paymentPreviewPost();
      this.paymentMethodRevokeStripe();
      this.paymentMethodRevokeRRC();
    },
    billingAddress(value) {
      if (!lodash.isNil(value)) {
        this.billingAddressContact = value.contact;
        this.billingAddressPhone = value.phone;
        this.billingAddressCountry = value.country;
        this.billingAddressZipCode = value.zipCode;
        this.billingAddressFullAddress = value.address;
      } else {
        this.billingAddressContact = null;
        this.billingAddressPhone = null;
        this.billingAddressCountry = null;
        this.billingAddressZipCode = null;
        this.billingAddressFullAddress = null;
      }
    },

    shippingAddress(value) {
      if (!lodash.isNil(value)) {
        this.shippingAddressContact = value.contact;
        this.shippingAddressPhone = value.phone;
        this.shippingAddressZipCode = value.zipCode;
        this.shippingAddressFullAddress = value.address;
      } else {
        this.shippingAddressContact = null;
        this.shippingAddressPhone = null;
        this.shippingAddressZipCode = null;
        this.shippingAddressFullAddress = null;
      }
    },
  },
  methods: {
    setShippingCountry() {
      if (!lodash.isNil(this.$store.getters.vChallengeCart)) {
        this.shippingAddressCountry =
          this.$store.getters.vChallengeCart.shippingCountry != -1
            ? this.$store.getters.vChallengeCart.shippingCountry
            : null;
      }
    },
    async getCountryName(id) {
      const response = await this.$store.dispatch("geoCountryIdGet", {
        id: id,
      });

      if (!lodash.isNil(response) && !lodash.isNil(response.data.data)) {
        return response.data.data.name;
      }

      return "";
    },
    paymentPreviewPost() {
      try {
        this.$store
          .dispatch("paymentPreviewPost", {
            data: {
              description: "Purchase Challenge Product",
              reason: {
                id: this.$route.params.id,
                mode: this.$route.params.mode,
              },
              method: this.method,
            },
          })
          .then((response) => {
            this.preview = response.data.data;
          })
          .catch((error) => {
            this.preview = null;
            if (!lodash.isNil(error.response)) {
              switch (error.response.status) {
                case 500:
                  this.$store.dispatch(
                    "error",
                    this.$t("error.profile-incomplete")
                  );
                  break;
                default:
                  this.$store.dispatch("error", error.response.data.message);
                  break;
              }
            }
          });
      } catch (_) {
        this.preview = null;
      }
    },
    paymentMethodCallbackCredentialGet(method) {
      this.$store
        .dispatch("paymentMethodCallbackCredentialGet", {
          method: method,
        })
        .then((response) => {
          switch (method) {
            case PaymentMethod.PayPal:
              this.payPalCredential = response.data.data;
              break;
            case PaymentMethod.Stripe:
              this.stripeCredential = response.data.data;
              loadStripe(this.stripeCredential.apiKey, {
                locale: moment.locale(),
              }).then((response) => {
                this.stripe = response;
              });
              break;
          }
        });
    },
    paymentMethodRevokeStripe() {
      if (
        !lodash.isNil(this.codeStripe) &&
        !lodash.isNil(this.metadata.stripe)
      ) {
        lodash.assign(this.metadata, { stripe: null });
        this.$store.dispatch("paymentMethodCallbackRevokeDelete", {
          method: PaymentMethod.Stripe,
          data: {
            code: this.codeStripe,
            description: "Revoke Payment",
          },
        });
      }
    },
    paymentMethodRevokeRRC() {
      if (
        !this.lodash.isNil(this.codeRRC) &&
        !this.lodash.isNil(this.metadata.rrc)
      ) {
        this.pendingPayment = false;
        this.$emit("pending", false);
        this.$store.dispatch("paymentMethodCallbackRevokeDelete", {
          method: PaymentMethod.RRC,
          data: {
            code: this.codeRRC,
            description: "Revoke Payment",
          },
        });
        this.codeRRC = null;
      }
    },
    async paymentPost() {
      this.executing = true;

      const payload = {
        method: this.method,
        reason: { id: this.$route.params.id, mode: this.$route.params.mode },
        description: "Purchase Challenge Product",
        billingAddress: `${this.billingAddressContact}, ${
          this.billingAddressPhone
        }, ${await this.getCountryName(this.billingAddressCountry)}, ${
          this.billingAddressZipCode
        }, ${this.billingAddressFullAddress}`,
        shippingAddress: `${this.shippingAddressContact}, ${
          this.shippingAddressPhone
        }, ${await this.getCountryName(this.shippingAddressCountry)}, ${
          this.shippingAddressZipCode
        }, ${this.shippingAddressFullAddress}`,
      };

      if (this.method == PaymentMethod.Stripe) {
        if (lodash.isNil(this.stripeCredential)) {
          return;
        } else {
          lodash.assign(payload, {
            metadata: {
              stripeMode: "intent",
            },
          });
        }
      }

      try {
        this.$store
          .dispatch("paymentPost", {
            data: payload,
          })
          .then((response) => {
            this.executing = false;
            this.pendingPayment = true;

            if (this.method == PaymentMethod.WalletBalance) {
              this.$store.dispatch("vchallengeShopCartGet");
              this.$store.dispatch("walletGet");
              this.$router.push({
                name: "checkout-confirmed",
              });
            } else if (this.method == PaymentMethod.Stripe) {
              this.codeStripe = response.data.data.code;
              lodash.assign(this.metadata, {
                stripe: response.data.data.metadata.stripe,
              });

              if (this.stripe != null) {
                var elements = this.stripe.elements();
                var style = {
                  base: {
                    fontFamily: '"Josefin Sans", sans-serif',
                    color: "#FFFFFF",
                    fontSmoothing: "antialiased",
                    fontSize: "15px",
                    "::placeholder": {
                      color: "#9aace5",
                    },
                  },
                  invalid: {
                    color: "#dc3545",
                    iconColor: "#dc3545",
                  },
                };

                this.card = elements.create("card", {
                  style: style,
                  classes: {
                    base: "card-field",
                  },
                });
                this.card.mount("#card-element");
                this.card.on("ready", function (event) {
                  if (event) {
                    document.getElementById("form-stripe").style.display =
                      "block";
                  }
                });
              }
            } else if (this.method == PaymentMethod.RRC) {
              this.codeRRC = response.data.data.code;
              this.lodash.assign(this.metadata, {
                rrc: response.data.data.metadata.rrc,
              });

              const topicRRC = `payment/${this.codeRRC.trim()}/rrc`;

              this.mqttClient.subscribe(topicRRC, 2);
              this.mqttClient.on("message", (topic, payload) => {
                if (topicRRC == topic) {
                  console.log(JSON.parse(payload.toString("utf-8")).status);
                  if (JSON.parse(payload.toString("utf-8")).status == 1) {
                    this.$store.dispatch("vchallengeShopCartGet");
                    this.$store.dispatch("walletGet");
                    this.$store.dispatch("walletRRCGet");
                    this.pendingPayment = false;
                    this.$router.push({
                      name: "checkout-confirmed",
                    });
                  } else {
                    this.paymentMethodRevokeRRC();
                    this.executing = false;
                    this.$store.dispatch(
                      "error",
                      this.$t("error.payment-incomplete")
                    );
                  }
                  this.mqttClient.unsubscribe(topic);
                }
              });
            }
          })
          .catch(() => {
            this.executing = false;
          });
      } catch (_) {
        this.executing = false;
      }
    },
    confirmCardPayment() {
      try {
        if (
          lodash.isNil(document.getElementById("card-name").value) ||
          lodash.isEmpty(document.getElementById("card-name").value)
        ) {
          return;
        }

        this.executing = true;
        var card = this.card;

        this.stripe
          .confirmCardPayment(this.metadata.stripe.client_secret, {
            payment_method: {
              card: card,
              billing_details: {
                name: document.getElementById("card-name").value,
              },
            },
          })
          .then((confirmResult) => {
            if (!lodash.isNil(confirmResult.error)) {
              this.executing = false;
              this.$store.dispatch("error", confirmResult.error.message);
            } else {
              if (confirmResult.paymentIntent.status === "requires_action") {
                this.stripe
                  .confirmCardPayment(this.metadata.stripe.client_secret)
                  .then((result) => {
                    if (result.error) {
                      this.executing = false;
                    } else {
                      this.paymentMethodCallbackAcceptPut(
                        PaymentMethod.Stripe,
                        {
                          intentID: this.metadata.stripe.id,
                          code: this.codeStripe,
                        }
                      );
                    }
                  });
              } else {
                this.paymentMethodCallbackAcceptPut(PaymentMethod.Stripe, {
                  intentID: this.metadata.stripe.id,
                  code: this.codeStripe,
                });
              }
            }
          })
          .catch((error) => {
            this.executing = false;
            this.$store.dispatch("error", this.getErrorMessages(error));
          });
      } catch (error) {
        this.executing = false;
        this.$store.dispatch("error", this.getErrorMessages(error));
      }
    },
    paymentMethodCallbackAcceptPut(method, data) {
      this.$store
        .dispatch("paymentMethodCallbackAcceptPut", {
          method: method,
          data: data,
        })
        .then(() => {
          this.$store.dispatch("vchallengeShopCartGet");
          this.$store.dispatch("walletGet");
          this.$store.dispatch("walletRRCGet");
          this.$router.push({
            name: "checkout-confirmed",
          });
        })
        .catch((error) => {
          this.paymentMethodRevokeStripe();
          this.executing = false;
          this.$store.dispatch("error", this.getErrorMessages(error));
        });
    },
  },
  mounted() {
    this.paymentPreviewPost();
    this.setShippingCountry();
    this.paymentMethodCallbackCredentialGet(PaymentMethod.Stripe);
    this.$store.dispatch("walletGet");
    this.$store.dispatch("walletRRCGet");
    this.connectMqttClient();
  },
  beforeRouteLeave(routeTo, routeFrom, next) {
    if (!this.pendingPayment) {
      next();
    } else {
      return false;
    }
  },
  components: {
    SelectAddress,
    SelectCountry,
    Notification,
    TimeCounter,
  },
};
</script>

<template>
  <!-- inner-hero-section start -->
  <div class="inner-hero-section">
    <div class="bg-shape">
      <img src="@/assets/images/elements/inner-hero-shape.png" alt="image" />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <ul class="page-list">
            <li>
              <router-link :to="{ name: 'home' }" tag="a">
                {{ $t("text.home") }}
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'shopping-cart' }" tag="a">
                {{ $t("text.my-cart") }}
              </router-link>
            </li>
            <li class="active">
              {{ $t("text.checkout") }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- inner-hero-section end -->

  <!-- checkout section start -->
  <section class="pb-120 mt-minus-300">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="checkout-area">
            <Notification />

            <div class="row">
              <div class="col-lg-7">
                <div class="checkout-form-area">
                  <div class="personal-details">
                    <h3 class="title">
                      {{ $t("text.billing-information") }}
                    </h3>
                    <form class="personal-details-form">
                      <div class="form-row">
                        <div class="form-group col-lg-12">
                          <SelectAddress v-model="billingAddress" />
                        </div>
                        <div class="form-group col-lg-6">
                          <input
                            type="text"
                            v-model="billingAddressContact"
                            :placeholder="$t('placeholder.contact')"
                          />

                          <div
                            class="input-errors"
                            v-if="v$.billingAddressContact.$invalid"
                          >
                            {{ $t("error.field-required") }}
                          </div>
                        </div>
                        <div class="form-group col-lg-6">
                          <input
                            type="phone"
                            v-model="billingAddressPhone"
                            :placeholder="$t('placeholder.phone')"
                          />

                          <div
                            class="input-errors"
                            v-if="v$.billingAddressPhone.$invalid"
                          >
                            {{ $t("error.field-required") }}
                          </div>
                        </div>
                        <div class="form-group col-lg-6">
                          <SelectCountry v-model="billingAddressCountry" />

                          <div
                            class="input-errors"
                            v-if="v$.billingAddressCountry.$invalid"
                          >
                            {{ $t("error.field-required") }}
                          </div>
                        </div>
                        <div class="form-group col-lg-6">
                          <input
                            type="phone"
                            v-model="billingAddressZipCode"
                            :placeholder="$t('placeholder.zip-code')"
                          />

                          <div
                            class="input-errors"
                            v-if="v$.billingAddressZipCode.$invalid"
                          >
                            {{ $t("error.field-required") }}
                          </div>
                        </div>
                        <div class="form-group col-lg-12">
                          <input
                            type="phone"
                            v-model="billingAddressFullAddress"
                            :placeholder="$t('placeholder.full-address')"
                          />

                          <div
                            class="input-errors"
                            v-if="v$.billingAddressFullAddress.$invalid"
                          >
                            {{ $t("error.field-required") }}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div class="personal-details mt-30">
                    <h3 class="title">
                      {{ $t("text.shipping-information") }}
                    </h3>
                    <form class="personal-details-form">
                      <div class="form-row">
                        <div class="form-group col-lg-12">
                          <SelectAddress
                            v-model="shippingAddress"
                            :country-filter="shippingAddressCountry"
                          />
                        </div>
                        <div class="form-group col-lg-6">
                          <input
                            type="text"
                            v-model="shippingAddressContact"
                            :placeholder="$t('placeholder.contact')"
                          />

                          <div
                            class="input-errors"
                            v-if="v$.shippingAddressContact.$invalid"
                          >
                            {{ $t("error.field-required") }}
                          </div>
                        </div>
                        <div class="form-group col-lg-6">
                          <input
                            type="phone"
                            v-model="shippingAddressPhone"
                            :placeholder="$t('placeholder.phone')"
                          />

                          <div
                            class="input-errors"
                            v-if="v$.shippingAddressPhone.$invalid"
                          >
                            {{ $t("error.field-required") }}
                          </div>
                        </div>
                        <div class="form-group col-lg-6">
                          <SelectCountry
                            v-model="shippingAddressCountry"
                            disabled
                          />
                        </div>
                        <div class="form-group col-lg-6">
                          <input
                            type="phone"
                            v-model="shippingAddressZipCode"
                            :placeholder="$t('placeholder.zip-code')"
                          />

                          <div
                            class="input-errors"
                            v-if="v$.shippingAddressZipCode.$invalid"
                          >
                            {{ $t("error.field-required") }}
                          </div>
                        </div>
                        <div class="form-group col-lg-12">
                          <input
                            type="phone"
                            v-model="shippingAddressFullAddress"
                            :placeholder="$t('placeholder.full-address')"
                          />

                          <div
                            class="input-errors"
                            v-if="v$.shippingAddressFullAddress.$invalid"
                          >
                            {{ $t("error.field-required") }}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div class="payment-details mt-30">
                    <h3 class="title">
                      {{ $t("text.payment-option") }}
                    </h3>

                    <form class="payment-form">
                      <div class="payment-methods">
                        <button
                          type="button"
                          :class="method == 6 ? 'checked' : ''"
                          @click="method = 6"
                          style="min-width: 130px"
                        >
                          <i class="las la-wallet"></i>
                          <span> ЯRC </span>
                        </button>
                      </div>

                      <template v-if="method == 1">
                        <h5 class="payment-form__title mb-0">
                          {{
                            $t("text.pay-with", {
                              value: $t("text.available-balance"),
                            })
                          }}
                        </h5>
                        <div v-if="!lodash.isNil($store.getters.wallet)">
                          <span class="price">
                            {{
                              `$${lodash.floor(
                                $store.getters.wallet.balance,
                                2
                              )}`
                            }}
                          </span>
                        </div>

                        <button
                          type="button"
                          class="cmn-btn"
                          :disabled="executing || v$.$invalid"
                          @click="paymentPost"
                        >
                          <span
                            v-if="executing"
                            class="spinner-border spinner-border-sm mb-1 mr-2"
                            role="status"
                            aria-hidden="true"
                          ></span>

                          {{ $t("button.pay-now") }}
                        </button>
                      </template>
                      <template v-else-if="method == 6">
                        <h5 class="payment-form__title mb-0">
                          {{
                            $t("text.pay-with", {
                              value: "ЯRC",
                            })
                          }}
                        </h5>

                        <div v-if="!lodash.isNil($store.getters.walletRRC)">
                          <span class="price">
                            {{
                              `${lodash.floor(
                                $store.getters.walletRRC.coins,
                                8
                              )} ЯRC`
                            }}
                          </span>
                        </div>

                        <button
                          type="button"
                          class="cmn-btn"
                          :disabled="
                            executing ||
                            v$.$invalid ||
                            !lodash.isNil(codeRRC) ||
                            !lodash.isNil(metadata.rrc)
                          "
                          @click="paymentPost"
                        >
                          <span
                            v-if="executing"
                            class="spinner-border spinner-border-sm mb-1 mr-2"
                            role="status"
                            aria-hidden="true"
                          ></span>

                          {{ $t("button.generate-payment-code") }}
                        </button>
                      </template>
                      <template v-else-if="method == 7">
                        <h5 class="payment-form__title mb-0">
                          {{
                            $t("text.pay-with", {
                              value: "Stripe",
                            })
                          }}
                        </h5>

                        <template v-if="lodash.isNil(metadata.stripe)">
                          <button
                            type="button"
                            class="cmn-btn"
                            :disabled="executing || v$.$invalid"
                            @click="paymentPost"
                          >
                            <span
                              v-if="executing"
                              class="spinner-border spinner-border-sm mb-1 mr-2"
                              role="status"
                              aria-hidden="true"
                            ></span>

                            {{ $t("button.generate-payment") }}
                          </button>
                        </template>
                      </template>
                    </form>

                    <template v-if="method == 6">
                      <template
                        v-if="
                          !lodash.isNil(codeRRC) && !lodash.isNil(metadata.rrc)
                        "
                      >
                        <div class="text-center mt-4">
                          <img
                            class="my-3"
                            :src="
                              getQrCode(
                                `${metadata.rrc.address}?amount=${metadata.rrc.coins}`
                              )
                            "
                            alt="image"
                          />

                          <h4 class="mb-0 text-warning">
                            <b>{{ `${metadata.rrc.coins} ЯRC` }}</b>
                          </h4>

                          <h5 class="my-3">
                            {{ metadata.rrc.address }}
                          </h5>

                          <p class="mt-3">
                            {{ $t("text.automatic-payment-rrc") }}
                          </p>
                          <p class="mb-0">
                            {{ $t("text.remaining-life") }}
                            <label>
                              <b>
                                <TimeCounter
                                  :expiresAt="new Date(metadata.rrc.expiresAt)"
                                  :warning-time="300000"
                                  @timeover="paymentMethodRevokeRRC"
                                />
                              </b>
                            </label>
                          </p>

                          <button
                            type="button"
                            class="cmn-btn"
                            :disabled="
                              lodash.isNil(codeRRC) ||
                              lodash.isNil(metadata.rrc)
                            "
                            @click="paymentMethodRevokeRRC"
                          >
                            {{ $t("button.cancel") }}
                          </button>
                        </div>
                      </template>
                    </template>
                    <template v-else-if="method == 7">
                      <form
                        class="personal-details-form mt-30"
                        style="display: none"
                        id="form-stripe"
                      >
                        <div class="form-row">
                          <div class="form-group col-lg-12">
                            <input
                              id="card-name"
                              type="text"
                              :placeholder="$t('placeholder.name')"
                            />
                          </div>

                          <div class="form-group col-lg-12">
                            <div id="card-element"></div>
                          </div>
                        </div>

                        <button
                          id="stripe-button"
                          class="cmn-btn"
                          :disabled="executing"
                          @click="confirmCardPayment"
                        >
                          <span
                            v-if="executing"
                            class="spinner-border spinner-border-sm mb-1 mr-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          {{ $t("button.pay-now") }}
                        </button>
                      </form>
                    </template>
                  </div>
                </div>
              </div>

              <div class="col-lg-5 mt-lg-0 mt-4">
                <div class="checkout-wrapper">
                  <div class="checkout-wrapper__header">
                    <h3>
                      {{ $t("text.payment-preview") }}
                    </h3>
                  </div>
                  <div class="checkout-wrapper__body">
                    <template v-if="!lodash.isNil(preview)">
                      <ul class="price">
                        <li>
                          <div class="left">
                            <h4 class="caption">
                              {{ $t("text.subtotal") }}
                            </h4>
                          </div>
                          <div class="right">
                            <span class="price">
                              {{ `$${lodash.floor(preview.amount, 2)}` }}
                            </span>
                          </div>
                        </li>

                        <li>
                          <div class="left">
                            <h4 class="caption">
                              {{ $t("text.shipping") }}
                            </h4>
                          </div>
                          <div class="right">
                            <span class="price">
                              {{ `$${lodash.floor(preview.shipping, 2)}` }}
                            </span>
                          </div>
                        </li>

                        <li>
                          <div class="left">
                            <h4 class="caption">
                              {{ $t("text.taxes") }}
                            </h4>
                            <span>
                              {{ `${preview.taxesPercent}%` }}
                            </span>
                          </div>
                          <div class="right">
                            <span class="price">
                              {{ `$${lodash.floor(preview.taxes, 2)}` }}
                            </span>
                          </div>
                        </li>

                        <li>
                          <div class="left">
                            <h4 class="caption">
                              {{ $t("text.fee") }}
                            </h4>
                            <span>
                              {{ `${preview.feePercent}%` }}
                            </span>
                          </div>
                          <div class="right">
                            <span class="price">
                              {{ `$${lodash.floor(preview.fee, 2)}` }}
                            </span>
                          </div>
                        </li>

                        <li>
                          <div class="left">
                            <h4 class="caption">
                              {{ $t("text.total") }}
                            </h4>
                          </div>
                          <div class="right">
                            <span class="price">
                              {{ `$${lodash.floor(preview.total, 2)}` }}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- checkout section end -->
</template>

<style lang="css">
.card-field {
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 5px;
  min-height: auto;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #8991d3;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  color: #ffffff;
  background-color: transparent !important;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  font-family: "Josefin Sans", sans-serif !important;
}
</style>