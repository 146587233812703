<script>
import Multiselect from "@vueform/multiselect";
import lodash from "lodash";

export default {
  setup() {
    return {
      lodash: lodash,
    };
  },
  props: {
    value: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    countryFilter: {
      type: Number,
      default: null,
    },
  },
  model: {
    prop: "value",
    event: "input",
  },
  data() {
    return {
      address: null,
    };
  },
  components: {
    Multiselect,
  },
  watch: {
    address(value) {
      this.$emit("input", value);
    },
  },
  computed: {
    options() {
      if (!lodash.isNil(this.$store.getters.account)) {
        if (lodash.isNil(this.countryFilter)) {
          return this.$store.getters.account.profile.addresses;
        } else {
          return lodash.filter(this.$store.getters.account.profile.addresses, {
            country: this.countryFilter,
          });
        }
      }

      return [];
    },
  },
  methods: {
    reset() {
      this.$emit("input", null);
      this.address = null;
    },
  },
};
</script>
<template>
  <Multiselect
    key="select-country"
    v-model="address"
    :options="options"
    :disabled="lodash.isEmpty(options) || disabled"
    :placeholder="`--${$t('placeholder.select')}--`"
    :searchable="true"
    trackBy="name"
    valueProp="_id"
    :classes="{
      search: 'multiselect-search',
    }"
    :object="true"
  >
    <template v-slot:singlelabel="{ value }">
      <div class="multiselect-single-label">
        {{ value.name }}
      </div>
    </template>

    <template v-slot:option="{ option }">
      {{ option.name }}
    </template>
  </Multiselect>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.multiselect {
  border: none !important;
  background: transparent !important;
  color: white !important;
}
.multiselect.is-active {
  box-shadow: none !important;
}
.multiselect-search,
.multiselect.is-disabled {
  border-radius: 0 !important;
  border-bottom: 1px solid #8991d3 !important;
}
.multiselect-single-label {
  padding-left: 0 !important;
}
</style>